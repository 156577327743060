import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllUsers,
  selectUsers,
  handlePasswordDriver
} from "../../redux/slices/users";
import {getAllVehicles, selectVehicles, removeMonitorVehicle} from '../../redux/slices/vehicles';
import {getAllBranches, selectBranches} from '../../redux/slices/branches';
import VehiclesUI from './components/VehiclesUI';

const Vehicles = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [editorPasswordVisible,handleEditorPasswordVisible] = useState(false)
  const [vehicle, handleVehicle] = useState(null);
  const vehicles = useSelector(selectVehicles);
  const branches = useSelector(selectBranches);
  const userList = useSelector(selectUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const [response, res_branches] = await Promise.all([
        dispatch(getAllVehicles()),
        dispatch(getAllBranches()),
        dispatch(getAllUsers())
      ]);
      if (response.status !== 'success') {
        message.error('¡Hubo un problema!');
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);


  const openEditor = vehicleToEdit => {
    handleVehicle(vehicleToEdit);
    handleEditorVisible(true);
  };

  const openEditorPassword = (vehicleDetail) => {
    handleVehicle(vehicleDetail);
    handleEditorPasswordVisible(true)
  }
  const newVehicle = () => {
    handleVehicle(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleVehicle(null);
    handleEditorVisible(false);
  };

  const closeEditorPassword = () => {
    handleVehicle(null);
    handleEditorPasswordVisible(false);
  };


  const confirmDelete = idVehicle => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar este driver?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeVehicle(idVehicle);
      },
      onCancel() {},
    });
  };

  const removeVehicle = async (idVehicle) => {
    const response = await dispatch(removeMonitorVehicle(idVehicle));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Moto eliminada correctamente');
    }
  }

  const changePassword = async (password, vehicle) => {
    const response = await dispatch(handlePasswordDriver(vehicle.id, `${password}`));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Contraseña actualizada correctamente!");
    }
  };

  return (
    <VehiclesUI
      loading={loading}
      branches={branches}
      vehicle={vehicle}
      vehicles={vehicles}
      userList={userList}
      openEditor={openEditor}
      openEditorPassword={openEditorPassword}
      editorVisible={editorVisible}
      editorPasswordVisible={editorPasswordVisible}
      closeEditor={closeEditor}
      closeEditorPassword={closeEditorPassword}
      changePassword={changePassword}
      newVehicle={newVehicle}
      confirmDelete={confirmDelete}
      handleVehicle={handleVehicle}
    />
  );
}

export default Vehicles;
