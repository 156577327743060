import React from "react";
import styles from "../styles/DriverUI.module.css";
import HeaderList from "../../../components/HeaderList";
import { ReactComponent as PlaceholderImage } from "../../../assets/icons/placeholder-image.svg";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import { beforeUpload, dummyRequest } from "../../../utils/images";
import cx from "classnames";
import Close from "../../../components/Close";
import { GoogleMap, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import { ReactComponent as Gps } from "../../../assets/icons/gps.svg";
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_LIBRARIES } from "../../../utils/constants";
import { handleDUI, handlePhone, handlePlate } from "../../../utils/format";
import DriverSchedules from "./DriverSchedules";

const DriverUI = ({
  driverId,
  handleImage,
  loading,
  image,
  branches,
  showMap,
  handleShowMap,
  onFinish,
  form,
  sending,
  confirmEnabledDriver,
  confirmDisabledDriver,
  disabledLoading,
  enabledLoading,
  driver,
  openDriverSchedules,
  closeDriverSchedules,
  showDriverSchedules
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries:GOOGLE_MAPS_LIBRARIES
  });
  if (loading) {
    return (
      <div className={styles.loaderWrap}>
        <Spin size="large" />
      </div>
    );
  }

  console.log(driver)
  return (
    <div className={styles.view}>
      <HeaderList back="/ajustes/drivers" title="Detalle de driver" />
      <div className={styles.containerLabelSwitches}>
        <p className={styles.label}>{`${
          driverId ? "Editar" : "Crear"
        } Driver`}</p>
      </div>
      <div className={styles.formEditor}>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <div className={styles.permissions}>
            {/*    <Form.Item
              name="auto_dispatch"
              label={<p className={styles.label}>DESPACHO</p>}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item> */}
            <Form.Item
              name="active"
              label={<p className={styles.label}>ACTIVAR</p>}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
          <Row gutter={{ xs: 0, sm: 18, md: 24 }}>
            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
              <Upload
                name="avatar"
                listType="picture-card"
                className={styles.avatarUploader}
                beforeUpload={beforeUpload}
                maxCount={1}
                showUploadList={false}
                customRequest={(e) => dummyRequest(e, handleImage)}
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "20px",
                  backgroundColor: "var(--darkGray)",
                  borderColor: "var(--grey)",
                  overflow: "hidden",
                }}
              >
                {image ? (
                  <div
                    className={styles.photo}
                    style={{ backgroundImage: `url(${image})` }}
                  />
                ) : (
                  <div className={styles.placeholderImg}>
                    <PlaceholderImage />
                  </div>
                )}
              </Upload>
              <span className={styles.disclaimer}>
                Foto de perfil 500px x 500px.
              </span>
              {driverId && (
                <div className={styles.subActions}>
                  <Button
                    htmlType="button"
                    onClick={() => handleShowMap(true)}
                    className={cx(styles.btn, styles.btnMap)}
                  >
                    Ultima Ubicación
                  </Button>
                  <Button
                    htmlType="button"
                    className={cx(styles.btn, styles.btnAvailable)}
                    loading={enabledLoading}
                    onClick={() => confirmEnabledDriver(driverId)}
                  >
                    Habilitar Driver
                  </Button>
                  <Button
                    htmlType="button"
                    className={cx(styles.btn, styles.btnLock)}
                    loading={disabledLoading}
                    onClick={() => confirmDisabledDriver(driverId)}
                  >
                    Bloquear Driver
                  </Button>
                  <Button
                  htmlType="button"
                  className={cx(styles.btn)}
                  onClick={openDriverSchedules}
                  >
                   Horarios Disponibles
                  </Button>
                </div>
              )}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 9 }}>
              <Form.Item
                name="name"
                label={<span className={styles.label}> NOMBRE</span>}
                rules={[{ required: true, message: "Ingresa un nombre" }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                name="lastname"
                label={<span className={styles.label}> APELLIDO</span>}
                rules={[{ required: true, message: "Ingresa un apellido" }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                name="email"
                label={<span className={styles.label}> CORREO</span>}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Ingresa un correo",
                  },
                ]}
              >
                <Input size="large" className="input" />
              </Form.Item>

              <Form.Item
                name="plate"
                label={
                  <span className={styles.label}> PLACA DEL VEHÍCULO</span>
                }
                rules={[{ message: "Ingresa una placa" }]}
              >
                <Input size="large" className="input" onInput={handlePlate} />
              </Form.Item>
              <Form.Item
                name="phone"
                label={
                  <span className={styles.label}> NUMERO DE TELÉFONO</span>
                }
                rules={[
                  { required: true, message: "Ingresa un numero de teléfono" },
                ]}
              >
                <Input size="large" className="input" onInput={handlePhone} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 9 }}>
              <Form.Item
                name="address"
                label={
                  <span className={styles.label}> DIRECCIÓN DE RESIDENCIA</span>
                }
                rules={[{ message: "Ingresa una dirección" }]}
              >
                <Input size="large" className="input" />
              </Form.Item>
              <Form.Item
                name="nationalId"
                label={
                  <span className={styles.label}>
                    NUMERO DE IDENTIFICACIÓN (DUI)
                  </span>
                }
                rules={[{ message: "Ingresa el DUI" }]}
              >
                <Input size="large" className="input" onInput={handleDUI} />
              </Form.Item>
              <Form.Item
                name="gender"
                label={<span className={styles.label}>GÉNERO</span>}
                rules={[{ message: "Ingresa un genero" }]}
              >
                <Select size="large" className="input">
                  <Select.Option value={"male"}>Hombre</Select.Option>
                  <Select.Option value={"feminine"}>Mujer</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="branchId"
                label={<span className={styles.label}>SUCURSAL</span>}
                rules={[{ required: true, message: "Selecciona una sucursal" }]}
              >
                <Select
                  size="large"
                  className="input"
                  options={branches.map((item) => {
                    return { value: item.id, label: item.zone };
                  })}
                />
              </Form.Item>
              {!driverId && (
                <Form.Item
                  name="password"
                  label={<span className={styles.label}> CONTRASEÑA</span>}
                  rules={[
                    { required: true, message: "Ingresa una contraseña" },
                  ]}
                >
                  <Input.Password
                    visibilityToggle
                    size="large"
                    className="input"
                    autoComplete="new-password"
                  />
                </Form.Item>
              )}
              <Form.Item label=" ">
                <div className={styles.actions}>
                  <Button
                    loading={sending}
                    type="primary"
                    htmlType="submit"
                    className={cx(styles.btn, styles.submit)}
                  >
                    GUARDAR
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Modal
        footer={false}
        destroyOnClose
        visible={showDriverSchedules}
        onCancel={openDriverSchedules}
        closable={false}
        >
          <DriverSchedules closeDriverSchedules={closeDriverSchedules} driver={driver} />
        </Modal>
        <Modal
          footer={false}
          destroyOnClose
          visible={showMap}
          onCancel={() => handleShowMap(false)}
          closable={false}
        >
          <div className={styles.viewMap}>
            <div className={styles.header}>
              <span className={styles.title}>Ultima ubicación del driver</span>
              <Close action={() => handleShowMap(false)} />
            </div>

            {isLoaded && <DriverLocation latlng={{lat:parseFloat(driver?.latitude),lng:parseFloat(driver?.longitude)}} />}
          </div>
        </Modal>
      </div>
    </div>
  );
};

const DriverLocation = ({latlng}) => {
  const center = latlng

  const containerStyle = {
    width: "100%",
    height: "400px",
  };
  console.log(latlng)
  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={16}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: false,
        }}
      >
        <OverlayView
          position={center}
          getPixelPositionOffset={() => ({ x: -20, y: -52 })}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div>
            <Gps />
          </div>
        </OverlayView>
      </GoogleMap>
    </div>
  );
};
export default DriverUI;
